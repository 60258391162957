// Here you can add other styles
:root {
  --cui-sidebar-occupy-start: 12rem,
}


@font-face {
  font-family: 'tm-logo-font';
  src: url('../assets/fonts/CallunaSans-Regular.otf');
}

@font-face {
  font-family: 'tm-logo-font-italic';
  src: url('../assets/fonts/CallunaSans-Italic.otf');
}

.hover-effect:hover {
  cursor: pointer;
}

.large-emphasis {
  font-size: 1.2rem;
  padding-left: 0.2rem;
}

.hidden {
  display: none;
}

.close-button {
  z-index: 1;
}

.close-button:hover {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.logo-container {
  color: inherit;
  display: flex;
  font-family: Georgia;
  padding: 15px;
  position: relative;
}

.logo-container--absolute {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

.logo-container img {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.logo-container .brand-name {
  margin: 0 0 2rem 0;
}

.logo-container-small img {
  width: 35px;
  height: 35px;
  margin-right: 5px;
}

.save-on-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
}

.Arrows {
  background: #ebedef !important;
}

.Arrows--step__current .Arrows--step--arrow {
  fill: #3399ff !important;
}

.Arrows--step__current .Arrows--step--content .Arrows--step--number {
  opacity: 1 !important;
  color: white;
}

.Arrows--step__current .Arrows--step--content .Arrows--step--label {
  color: white;
  opacity: 1;
}

.Arrows--step--arrow {
  fill: #687082 !important;
}

.Arrows--step--content .Arrows--step--number,
.Arrows--step__coming .Arrows--step--content .Arrows--step--number {
  color: white !important;
  opacity: 1 !important;
}

.table-container {
  overflow: scroll;
  height: calc(100vh);
}

.notes-user-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 5px;
  background: #1e2125;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.notes-title-header {
  margin: 0;
}

.notes-title-date {
  display: inline-block;
  font-size: 0.9rem;
}

.notes-content {
  padding-left: 45px;
}

.hyperlink {
  position: relative;
  opacity: 0.8;

  &__link {
    text-decoration: none;
    color: black;
  }

  &__icon {
    display: none;
    height: 11px;
    background-color: white;
    padding: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    box-shadow: 2px 1px 2px #b2b7ba;
  }

  &:hover {
    .hyperlink__icon {
      display: initial;
    }

    .hyperlink__link {
      color: black;
    }
  }
}

.as-textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
}

.as-textarea {
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: inherit;
  padding: 1px 6px;
}

.desktop-menu {
}

.mobile-menu {
  display: none;
}

.logo-timeout {
  transition: 2s;
}

.hide-header {
  display: none;
}

.avatar-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .avatar-icon {
    width: 35px;
    height: 35px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    p {
      color: white;
      margin: 0;
      font-size: 1rem;
    }
  }
}

@media (max-width: 700px) {
  .mobile-menu {
    display: initial;
  }

  .desktop-menu {
    display: none;
  }
}

.text-small-emphasis {
  color: rgba(44, 56, 74, 0.681);
  font-size: 0.8rem;
}

.close-drawer {
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
}

.drawer-content-layout {
  display: flex;
  flex-direction: column;
  height: 100%;

  .drawer-header {
    width: 100%;
  }

  .drawer-content {
    flex-grow: 1;
  }

  .drawer-footer {
    width: 100%;
  }
}

.bottom-button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.filters-badges-container {
  min-height: 27px;

  .filter-badge {
    display: flex;
    align-items: center;
    background-color: #1645AD !important;
    margin-right: 5px;
    margin-bottom: 2px;
    margin-top: 2px;
    color: white;
    opacity: 1;
  }

  .remove-badge {
    margin-left: 5px;
    &:hover {
      cursor: pointer;
    }
  }
}

input.underscore-input {
  width: 50px;
  border: none;
  border-bottom: 1px solid black;
}

input.user_input:focus {
  box-shadow: 0 4px 2px -2px #666;
  outline-width: 0;
}

.graphic-badges {
  width: 100%;
  display: flex;
  .badges__container {
    padding-top: 10px;
    padding-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    width: 80%;
  }

  .clear-button__container {
    display: flex;
    align-items: start;
    justify-content: end;
    width: 20%;
    padding-top: 10px;

    .clear-filters-button {
      border: 1px solid #51a8ff;
      background-color: #51a8ff;
      color: white;
      font-size: 0.8rem;
      padding: 2px 4px;
      margin-right: 5px;
      margin-bottom: 2px;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.brain-icon {
  height: 3.8rem;
  width: 3.8rem;
  margin-bottom: 0.5rem;
}

.territory-info-icon {
  margin-left: 10px;
  background-color: dodgerblue;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23px;
  width: 23px;
  border-radius: 50%;
  box-shadow: 0 2px 3px 1px #97999a;
}

.fancy-hover {
  &:hover {
    cursor: pointer;
  }
}

.chart-label {
  position: absolute;
  color: black;
  font-size: 1rem;
  left: 50%;
  top: 20%;
}

.fixed-filter-section {
  position: absolute;
  left: 0;
  right: 0;
}

.badge-segmentation-top {
  background-color: #b6eda4;
}

.badge-segmentation-next {
  background-color: #f2dea5;
}

.badge-segmentation-bottom {
  background-color: #f3c7d3;
}

.data-table-badge {
  border-radius: 12px;
  width: content-box;
  padding: 0.23rem 0.5rem;
  color: black;
}

.attribute-sweetspot-container {
  height: 80%;
}

.dashboard-tab {
  height: 100%;
  border-radius: 0;
  background-color: white;
  border: 1px solid white;
  padding: 0.38rem 0.78rem 0.38rem 0.78rem;
}

.dashboard-tab-active,
.dashboard-tab-active:active .dashboard-tab:focus .dashboard-tab:hover {
  color: white;
  border: 2px solid #3399ff;
  background-color: #3399ff;
}

.btn-primary {
  color: white;
}

.btn-primary:hover {
  color: white;
}

.register-button {
  color: white !important;
}

.register-button:active {
  color: white;
}

.register-button:hover {
  background-color: #4699ec;
}

.btn-export {
  border-radius: 0;
}

input:focus {
  border: 2px solid #2684FF;
  box-shadow: none !important;
}

.btn-export:focus,
.btn-export:active {
  color: white;
  outline: none;
  box-shadow: none;
}

.no-border-radius {
  border-radius: 0;
}

.tab-account-info {
  h2 {
    margin: 0;
  }
}

.tabs-container {
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
}

.tm-insights {
  overflow: scroll;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  ul {
    li {
      p{
        margin-bottom: 0;
      }
    }
  }
}

.insights-title {
  font-size: 1rem
}

.legend-container {
  width: 100%;
  height: 100%;

  .hidden-header {
    height: 20%;
  }

  .centered-legends {
    height: 80%;
    display: flex;
    justify-content: start;
    align-items: center;

    .legend-list {
      list-style: none;
      padding: 0;
      margin: 0;

      &__item {
        font-size: .7rem;
        margin-bottom: 2px;
        .color-tag {
          display: inline-block;
          text-align: center;
          min-width: 2rem;
          margin-right: 5px;
          padding: 0 0.2rem 0 0.2rem
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.graphic-container {
  max-height: 160px;
}

@media screen and (max-height: 725px) {
  .kanban {
    padding-bottom: 20px;
  }
}
@media screen and (max-height: 1460px) {
  .legend-container .centered-legends .legend-list__item {
    font-size: 0.6rem;
  }
}

@media screen and (max-height: 880px) {
  .short-height::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .short-height::-webkit-scrollbar:vertical {
    width: 9px;
  }

  .short-height::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
  }
}
.custom-row  {
  padding: .2rem;
  .InovuaReactDataGrid__cell {
    padding: .4rem !important;

    .InovuaReactDataGrid__cell__content {
      height: 100% !important;
      display: flex;
      align-items: center;
    }
  }
}

.InovuaReactDataGrid__column-header__content {
  padding-right: 0.2rem !important;
}

.attribute-weighting {
  .MuiSlider-markLabel {
    line-height: 0.3;
  }
}

@media only screen and (min-width: 768px) {
  html:not([dir=rtl]) .sidebar.force-unhide:not(.sidebar-end) {
    margin-left: 0;
  }
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar{
    display: none;
  }
}

.close-spacing {
  margin-right: 0.8rem;
  margin-top: 0.7rem;
}
